export const initialGameState = [
  {
    level: 0,
    location: "platform",
    platform: {
      state: Array(5).fill([null, false]),
    },
  },
  {
    level: 1,
    squares: [
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "leftTopSquares",
      },
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "bottomRightSquares",
      },
    ],
  },
  {
    level: 2,
    squares: [
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "leftTopSquares",
      },
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "bottomRightSquares",
      },
    ],
  },
  {
    level: 3,
    squares: [
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "leftTopSquares",
      },
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "bottomRightSquares",
      },
    ],
  },
  {
    level: 4,
    squares: [
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "leftTopSquares",
      },
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "bottomRightSquares",
      },
    ],
  },
  {
    level: 5,
    squares: [
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "leftTopSquares",
      },
      {
        state: Array(9).fill([null, false]),
        status: "unknown",
        location: "bottomRightSquares",
      },
    ],
  },
  {
    level: 6,
    location: "platform",
    platform: {
      state: Array(1).fill([null, false]),
    },
  },
];

export const initialStatusOfPlayers = Array(5).fill([null,null, "dead"]);
