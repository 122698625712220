import React, { useState, useEffect } from "react";
import { Transaction,Connection,PublicKey } from "@solana/web3.js"; // Import Transaction
import "./App.css";
import { Buffer } from "buffer";
import { delay } from "lodash";
import EndGameModal from './components/EndGameModal/EndGameModal';
import Footer from "./components/Footer/Footer";
import GlassBridgeGameBoard from "./components/GlassBridgeGameBoard/GlassBridgeGameBoard";
import Header from "./components/Header/Header";
import Timer from "./components/Timer/Timer";
import socket from "./socket"; // Import the shared socket instance
import SlotMachine from "./SlotMachine";
import MusicPlayer from "./MusicPlayer"; // Import MusicPlayer

import {
  scrollToStart,
  unlockNextLevel, 
} from "./utils/gameCalculations";
import { initialGameState, initialStatusOfPlayers } from "./utils/gameStates";
import "./Game.css";
const {
  TOKEN_MINT_ADDRESS,
  VAULT,
  NETWORK,
} = require("./config");

window.Buffer = window.Buffer || Buffer;

export default function GlassBridgeGame() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [rooms, setRooms] = useState({});
  const [roomName, setRoomName] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isCreatingRoom, setIsCreatingRoom] = useState(false);
  const [gameStatus, setGameStatus] = useState("not playing");
  const [endGameText, setEndGameText] = useState("");
  const [unlockedLevel, setUnlockedLevel] = useState(-1);
  const [gameState, setGameState] = useState(initialGameState);
  const [clickingNotAllowed, setClickingNotAllowed] = useState(false);
  const [statusOfPlayers, setStatusOfPlayers] = useState(initialStatusOfPlayers);
  const [playersAliveCount, setPlayersAliveCount] = useState(3);
  const [levelPlayerIsSelectedAt, setLevelPlayerIsSelectedAt] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null); // Tracks the current player's wallet
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(30);
  const [showTooltip, setShowTooltip] = useState(false);
  const [roomTimers, setRoomTimers] = useState({}); // Timers for each room
  const [candidates, setCandidates] = useState([]);
  const [winner, setWinner] = useState(null);
  const [prize, setPrize] = useState(0);
  const [slotMachineVisible, setSlotMachineVisible] = useState(false);
  const [characterMap, setCharacterMap] = useState({});
  const [musicTrack, setMusicTrack] = useState("music/first.mp3"); // Default track for room listing
  const [isMuted, setIsMuted] = useState(false);
  const [currentPage, setCurrentPage] = useState("firstPage");
  const [tokenBalance, setTokenBalance] = useState(null);
  const [luckyWinners, setLuckyWinners] = useState([]);
  const LUCKY_DRAW_PERCENTAGES = [5, 3, 2]; // Percentages for 1st, 2nd, and 3rd

  const MINIMUM_ENTRY_FEE = 1000; // Minimum entry fee in TOKEN
  const TOKEN = "SQUID"
  const connection = new Connection(NETWORK);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (currentPage === "firstPage") {
      getTokenBalance();
    }
  }, [currentPage]);

  async function getTokenBalance() {
    const mintAddress = new PublicKey(TOKEN_MINT_ADDRESS);
    const walletAddress = new PublicKey(VAULT);
    let retries = 5;
  
    while (retries > 0) {
      try {
        const tokenAccounts = await connection.getParsedTokenAccountsByOwner(walletAddress, {
          mint: mintAddress,
        });
  
        if (tokenAccounts.value && tokenAccounts.value.length > 0) {
          const tokenAccount = tokenAccounts.value[0];
          const balance = tokenAccount.account.data.parsed.info.tokenAmount.uiAmount;
          setTokenBalance(formatBalance(balance)); // Set balance with formatted value
          return balance;
        } else {
          console.log("No token account found for this wallet and mint.");
          setTokenBalance("$0.00"); // No balance found
          return 0;
        }
      } catch (error) {
        if (error.message.includes("429")) {
          retries -= 1;
          const backoffTime = (5 - retries) * 1000;
          console.warn(`Retrying after ${backoffTime}ms...`);
          await delay(backoffTime);
        } else {
          console.error("Error fetching token balance:", error);
          break;
        }
      }
    }
  
    setTokenBalance("$0.00");
    return 0;
  }


  useEffect(() => {
    if (currentRoom) {
      setMusicTrack("/music/bridge.mp3"); // Game room music
    } else if(currentPage=== "firstPage" ){
      setMusicTrack("/music/first.mp3"); // Game room music
    }
    else {
      setMusicTrack("/music/roomlist.mp3"); // Room listing music
    }
  }, [currentRoom,currentPage]);

  const handleToggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  useEffect(() => {
    socket.on("lucky_draw_winners", ({ luckyWinners, message }) => {
      console.log("Received lucky draw winners:", luckyWinners);
      setLuckyWinners(luckyWinners || []); // Save winners to state
    });
  
    return () => {
      socket.off("lucky_draw_winners");
    };
  }, []);

  useEffect(() => {
    socket.connect(); 
    socket.on("connect", () => {
      console.log("Connected with socket ID:", socket.id);
      
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected:", socket.id);
    });

    socket.on("start_game", ({ gameState: updatedGameState,currentPlayer, intialLevel,statusOfPlayers,playersAliveCount }) => {
      setGameState(updatedGameState); // Update the game state
      setUnlockedLevel(intialLevel)
      setCurrentPlayer(currentPlayer); // Set the current player
      setGameStatus("in progress"); // Start the game
      unlockNextLevel(setUnlockedLevel);
      setStatusOfPlayers(statusOfPlayers);
      setPlayersAliveCount(playersAliveCount);
      scrollToStart();
    });

    socket.on("start_random_selection", ({ candidates, winner, prize }) => {
      if (!candidates || candidates.length === 0) {
         console.error("No candidates provided for the slot machine.");
         return;
      }
      console.log("Candidates for slot machine:", candidates);
      setCandidates(candidates);
      setWinner(winner);
      console.log("prize",prize)
      setPrize(prize); // Set the prize amount
      setSlotMachineVisible(true);
      // Hide the slot machine after 3 seconds of showing the winner
      setTimeout(() => {
         setSlotMachineVisible(false);
      }, 8000); // 5 seconds of spinning + 3 seconds of showing the winner
   });

    socket.on("update_character_map", (updatedCharacterMap) => {
      console.log("Front players:", updatedCharacterMap);
      setCharacterMap(updatedCharacterMap);
    });
    socket.emit("get_rooms", (roomList) => {
      setRooms(roomList); // Set the initial rooms
    });
      // Listen for game state updates
    socket.on("update_game_state", (updatedGameState) => {
      setGameState(updatedGameState);
    });

    socket.on("update_game_room", ({ gameState: updatedGameState, currentPlayer, unlockedLevel,statusOfPlayers,playersAliveCount }) => {
      setGameState(updatedGameState); // Update the game state
      setCurrentPlayer(currentPlayer); // Update the current player
      setUnlockedLevel(unlockedLevel); // Update the unlocked level
      unlockNextLevel(setUnlockedLevel);
      setStatusOfPlayers(statusOfPlayers);
      setPlayersAliveCount(playersAliveCount);
    });

    socket.on("game_over", ({ winner, prize, message }) => {
      setGameStatus("game over");
      setEndGameText(winner ? `Player ${winner} won ${prize} ${TOKEN}!` : `Game Over: ${message}`);
      setRoomTimers((prev) => ({
        ...prev,
        [currentRoom?.roomName]: null,
      }));
      setIsTimerRunning(false); // Stop the timer
    });

    // Handle room updates
    socket.on("update_rooms", (updatedRooms) => {
      setRooms(updatedRooms);
    });

     // Fetch clients in the room if currentRoom exists
     if (currentRoom?.roomName) {
      socket.emit("get_clients_in_room", currentRoom.roomName, (clients) => {
        console.log(`Clients in room ${currentRoom.roomName}:`, clients);
      });
    }

    // Cleanup on component unmount
    return () => {
      socket.off("connect");
      socket.off("start_game");
      socket.off("update_game_state");
      socket.off("update_current_player");
      socket.off("update_rooms");
    };
  }, [walletAddress,currentRoom]);

  useEffect(() => {
    let timerInterval;
  
    if (isTimerRunning && currentRoom?.roomName) {
      if (!roomTimers[currentRoom.roomName]) {
        // Initialize the timer for the room if it doesn't exist
        setRoomTimers((prev) => ({
          ...prev,
          [currentRoom.roomName]: 30, 
        }));
      }
  
      timerInterval = setInterval(() => {
        setRoomTimers((prev) => {
          const currentTimer = prev[currentRoom.roomName] || 0;
  
          if (currentTimer > 0) {
            return {
              ...prev,
              [currentRoom.roomName]: currentTimer - 1,
            };
          } else {
            clearInterval(timerInterval);
            setIsTimerRunning(false);
  
            // Notify server about player timeout
            if (currentRoom?.roomName && walletAddress) {
              socket.emit("kill_player", { roomName: currentRoom.roomName, walletAddress });
            }
            return prev;
          }
        });
      }, 1000);
    }
  
    return () => clearInterval(timerInterval);
  }, [isTimerRunning, currentRoom?.roomName]);

  useEffect(() => {
    if (currentPlayer === walletAddress) {
      setIsTimerRunning(true); // Start timer when it's the player's turn
    } else {
      setIsTimerRunning(false); // Stop timer for other players
    }
  }, [currentPlayer, walletAddress]);

  const handleBackToRoomList = () => {
    setCurrentRoom(null); // Clear the current room
    setGameState(initialGameState)
    setGameStatus("not playing"); // Reset the game status
  };

  const handleConnectWallet = async () => {
    try {
      const provider = window.solana;
      if (!provider?.isPhantom) {
        throw new Error("Phantom Wallet not found or not connected.");
      }
      const response = await provider.connect();
      setWalletAddress(response.publicKey.toString());
    } catch (error) {
      console.error("Failed to connect wallet:", error);
      alert("Failed to connect wallet. Ensure you have Phantom Wallet installed.");
    }
  };

  function formatBalance(balance) {
    if (balance >= 1e9) {
      return `$${(balance / 1e9).toFixed(3)} B`; // Billion
    } else if (balance >= 1e6) {
      return `$${(balance / 1e6).toFixed(3)} M`; // Million
    } else if (balance >= 1e3) {
      return `$${(balance / 1e3).toFixed(3)} K`; // Thousand
    } else {
      return `$${balance.toFixed(2)}`; // Less than 1 thousand
    }
  }
  const formatEntryFee = (fee) => {
    if (fee >= 1000000) {
      return `${(fee / 1000000).toFixed(1)}M`;
    } else if (fee >= 1000) {
      return `${(fee / 1000).toFixed(1)}K`;
    }
    return fee;
  };
  const handleCreateRoom = (e) => {
    e.preventDefault();

    if (!roomName || !entryFee || isNaN(entryFee) || entryFee <= 0) {
      alert("Please provide a valid room name and entry fee!");
      return;
    }
    if (parseFloat(entryFee) < MINIMUM_ENTRY_FEE) {
      alert(`Entry fee must be at least ${MINIMUM_ENTRY_FEE} ${TOKEN}.`);
      return;
    }
    console.log("entryFee: ",entryFee)
    socket.emit(
      "pay_entryFee",
      { entryFee: entryFee, playerWallet: walletAddress },
      async (response) => {
        if (response.success) {
          console.log("response.success",response.success)
          console.log("response.transaction",response.transaction)
          try {
            // Deserialize transaction from server
            const transaction = Transaction.from(Buffer.from(response.transaction, "base64"));
            // Request the wallet to sign and send the transaction
            const provider = window.solana;
            if (!provider) {
              alert("Wallet provider not found!");
              return;
            }
            // Sign and send transaction
            const signedTransaction = await provider.signTransaction(transaction);
            await connection.sendRawTransaction(signedTransaction.serialize());
            socket.emit(
              "create_room",
              {
                roomName,
                entryFee: parseFloat(entryFee),
                ownerWallet: walletAddress,
              },
              (response) => {
                if (response.success) {
                  setCurrentRoom(response.roomCreated);
                  console.log(socket.id)
                  socket.emit("add_player_to_room", {
                    roomName: response.roomCreated.roomName,
                    walletAddress: walletAddress,
                });
                } else {
                  alert(`Failed to create room: ${response?.message}`);
                }
              }
            );
        } catch (error) {
          console.error("Transaction failed:", error);
          alert(`Failed to sign and send the transaction. Please ensure you have sufficient ${TOKEN}.`);
        }
        }
      else{
        alert(`Please connect the wallet`);
      }
      });
  };

  const handleJoinRoom = (room) => {

    if (!walletAddress) {
      alert("Please connect your wallet first to join the game and pay the entry fee!");
      return;
    }
    const walletInPlayers = room.players && room.players.some(player => player === walletAddress);
    if (walletInPlayers) {
        alert("You are already in this room!");
        return;
    }
    socket.emit(
      "pay_entryFee",
      { entryFee: room.entryFee, playerWallet: walletAddress },
      async (response) => {
        if (response.success) {
          try {
            // Deserialize transaction from server
            const transaction = Transaction.from(Buffer.from(response.transaction, "base64"));
  
            // Request the wallet to sign and send the transaction
            const provider = window.solana;
            if (!provider) {
              alert("Wallet provider not found!");
              return;
            }
  
            // Sign and send transaction
            const signedTransaction = await provider.signTransaction(transaction);
            await connection.sendRawTransaction(signedTransaction.serialize());
            setCurrentRoom(room);
            console.log(socket.id)
            socket.emit("add_player_to_room", {
                roomName: room.roomName,
                walletAddress: walletAddress,
              });
            
          } catch (error) {
            console.error("Transaction failed:", error);
            alert("Failed to sign and send the transaction.");
          }
        } else {
          alert(`Failed to join room: ${response.message}`);
        }
      }
    );
  };

  const handleShowRulesClick = () => {
    setShowTooltip((prev) => !prev);
  };

  const getCurrentTurnMessage = () => {
    if (!currentPlayer || !walletAddress) {
      // Check if all players are eliminated or dead
      const allDead = statusOfPlayers.every(([_, __, status]) => status === "dead");
      if (allDead) {
        return "Game over";
      }
      return "Waiting for the game to start...";
    }

    const playerStatus = statusOfPlayers.find(([player]) => player === walletAddress)?.[2];
    if (playerStatus === "dead") {
      return "Eliminated! Await luck draw if no winner.";
    }

    if (currentPlayer === walletAddress) {
      return "Your turn";
    }

    const displayedWallet = currentPlayer.slice(0, 4) + "..." + currentPlayer.slice(-4);
    return `Waiting for Player (${displayedWallet}) to move`;

  };
 
  const handleTileClick = (clickLevel, tileIndexNumber, tileValue) => {
    if (walletAddress !== currentPlayer) {
      setClickingNotAllowed(true);
      return;
    }else{
      setClickingNotAllowed(false);
    }
    if (clickLevel > unlockedLevel || tileValue === -1) return;
    setGameState((prevState) => {
      // Optimistic update
      const newState = [...prevState];
      // Update the tile's state optimistically
      // Add specific logic for tile update
      return newState;
    });
  
    socket.emit(
      "player_move",
      { roomName: currentRoom.roomName, walletAddress, tileIndex: tileIndexNumber },
      (response) => {
        if (!response.success) {
          // Rollback if the move fails
          setGameState((prevState) => /* revert to previous state */ prevState);
          alert(response.message);
        }
      }
    );
  };

  const handleUnknownSquareClick = (level, position) => {
    if (walletAddress !== currentPlayer) {
      setClickingNotAllowed(true);
      return;
    }else{
      setClickingNotAllowed(false);
    }
   
    if (position === 0) {
      return;
    }

    // Ensure the click is on the player's current level tile
    if (level !== unlockedLevel) {
      return;
    }
    socket.emit(
      "player_move",
      { roomName: currentRoom.roomName, walletAddress, tileIndex: position },
      (response) => {
        if (!response.success) {
          alert(response.message);
          return;
        }
      }
    );
  };
  return (
    <div className={`app ${currentPage === "firstPage" ? "first-page" : ""}`}
    style={{
      backgroundImage:
          currentPage === "firstPage"
        ? `url('/images/SQRoom001b.png')`
        : `url('/images/SGSTAGE002.png')`,
    }}>
      <header className="header">
        <button className="connect-button" onClick={handleConnectWallet}>
          {walletAddress
            ? `Wallet: ${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`
            : "Connect Wallet"}
        </button>
      </header>

      <main className={`main-content ${
          currentRoom && currentRoom.roomName
            ? "bridge-game"
            : isCreatingRoom || rooms
            ? "room-list"
            : ""
        }`}
      >
        {
        currentPage === "firstPage" ? (
          // First Page Content
          <div className="first-page">
            <div id="token-balance" className="token-balance">{tokenBalance}</div>
            {luckyWinners.length > 0 ? (
              <div className="LuckyWinners">
                Daily Lucky Draw
                  {luckyWinners.map((winner, index) => (
                    <li key={index}>
                      {winner.slice(0, 4)}...{winner.slice(-4)} -{" "}
                      {LUCKY_DRAW_PERCENTAGES[index]}%
                    </li>
                  ))}
              </div>
            ) : (
              <div className="LuckyWinners">
              <p>No Winners yet.</p>
              </div>
            )}
            <button
              className="start-glass-bridge-button"
              onClick={() => setCurrentPage("roomList")}
            >
            </button>
            <button
              className="start-aeiou-button"
              // onClick={() => setCurrentPage("roomList")}
            >
            </button>
          </div>
        ) : currentRoom && currentRoom.roomName ? (
          <div className="squid-game">
          <Header
            turnMessage={getCurrentTurnMessage()}
            roomName={currentRoom?.roomName || "N/A"}
            onConnectWallet={handleConnectWallet} // Pass the wallet connection function
            walletAddress={walletAddress}        // Pass the wallet address
            disableWallet={true} 
          />
        <Timer
            countdownTimer={roomTimers[currentRoom?.roomName] || 30}
            isTimerRunning={isTimerRunning}
            onShowRulesClick={handleShowRulesClick}
            showTooltip={showTooltip}
          />
          {slotMachineVisible && (
              <SlotMachine candidates={candidates} winner={winner} prize={prize} />
            )}
          <GlassBridgeGameBoard
            clickingNotAllowed={clickingNotAllowed}
            gameState={gameState}
            gameStatus={gameStatus}
            handleUnknownSquareClick={handleUnknownSquareClick}
            handleTileClick={handleTileClick}
            levelPlayerIsSelectedAt={levelPlayerIsSelectedAt}
            unlockedLevel={unlockedLevel}
            currentPlayer={currentPlayer}
            characterMap={characterMap}
          />
          <Footer statusOfPlayers={statusOfPlayers} playersAliveCount={playersAliveCount} />
          {gameStatus === "game over" ? (
            <EndGameModal endGameText={endGameText} onBackClick={handleBackToRoomList} />
          ) : null}
        </div>
        ) : isCreatingRoom ? (
          <form className="create-room-form" onSubmit={handleCreateRoom}>
            <div className="form-group">
              <label className="label-input" htmlFor="roomName">Room Name:</label>
              <input
                id="roomName"
                type="text"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label className="label-input" htmlFor="entryFee">Entry Fee ({TOKEN}):</label>
              <input
                id="entryFee"
                type="number"
                step="0.01"
                value={entryFee}
                onChange={(e) => setEntryFee(e.target.value)}
                required
              />
              {parseFloat(entryFee) < MINIMUM_ENTRY_FEE && (
                <p classname= "warning" style={{ color: "red" }}>
                  Entry fee must be at least {MINIMUM_ENTRY_FEE} {TOKEN}.
                </p>
              )}
            </div>
            <div className="button-container">
            <button className="create-button" type="submit"></button>
            <button className="back-create-button" type="button" onClick={() => setIsCreatingRoom(false)}></button>
          </div>
          </form>
        ) : currentPage === "roomList" ? (
          <>
            <div className="actions">
              <button
                className="start-room-button"
                onClick={() => setIsCreatingRoom(true)}
              >
              </button>
              <button className="back-first-page-button" type="button" onClick={() => setCurrentPage("firstPage")}></button>
            </div>
            <div className="room-section">
            <div className="room-list-container">
            <div className="room-list-header">
              <span className="column-header">Room Name</span>
              <span className="column-header">Players</span>
              <span className="column-header">Entry Fee ({TOKEN})</span>
              <span className="column-header">Play</span>
            </div>
            <div className="room-start-list">
              {
                rooms && Object.keys(rooms).length > 0 ? (
                  Object.values(rooms)
                    .filter((room) => room && room.roomName && room.players && room.entryFee) // Validate room properties
                    .map((room, index) => (
                      <div key={room.roomName || index} className="room">
                        <span className="room-name" title={room.roomName}>
                          {room.roomName.length > 15 ? `${room.roomName.slice(0, 15)}...` : room.roomName}
                        </span>
                        <span>{`${room.players.length}/3`}</span>
                        <span>
                          {formatEntryFee(room.entryFee)} 
                        </span>
                        <button
                          className="join-room-button"
                          onClick={() => handleJoinRoom(room)}
                          disabled={!walletAddress || room.isActive}
                        >
                          Join
                        </button>
                      </div>
                    ))
                ) : (
                  <p>No rooms available. Create one!</p>
                )
              }
              </div>
            </div>
            </div>
          </>
        ):(<></>)}
        {/* Music Player */}
      <MusicPlayer
        track={musicTrack}
        isMuted={isMuted}
        onToggleMute={handleToggleMute}
      />
      </main>
    </div>
  );
}
