 import React from "react";
import "./Platform.css";

const Platform = ({
  gameStatus,
  handleTileClick,
  platformAccess,
  platformState,
  characterMap, // Add characterMap as a prop
}) => {
  const { level, platform } = platformState;

  const gameSection = level === 0 ? "start" : "finish";
  const isPlatformUnlocked = platformAccess !== "locked";

  const tiles = platform?.state || []; // Handle undefined state

  const renderTile = (tileValue, selected, tileIndexNumber) => {

    const characterImage = characterMap[tileValue];

    const shortTileValue =
      typeof tileValue === "string" && tileValue.length > 10
        ? `${tileValue.slice(0, 1)}..${tileValue.slice(-4)}`
        : tileValue;
        // console.log("Character Map in Platform:", characterMap);
        return (
          <div
          className={`platform-tile ${gameSection === "finish" ? "platform-end-tile" : ""}`}
            key={`${gameSection}-${tileIndexNumber}`}
            onClick={() => handleTileClick(level, tileIndexNumber, tileValue)}
            title={tileValue} // Tooltip shows full name
          >
            {tileValue && (
        <span className="tile-wallet">{shortTileValue}</span> // Wallet address on top
      )}
      {characterImage  && (
        <img
        src={characterImage} // Use persistent character
        alt="Player Character"
        className="player-character"
      />
      )}
          </div>
        );
  };

  return (
    <>
      {tiles.map(([tileValue, selected], tileIndexNumber) => renderTile(tileValue, selected, tileIndexNumber))}
    </>
  );
};

export default Platform;
