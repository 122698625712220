import React from "react";

import './EndGameModal.css';

const EndGameModal = ({ endGameText,onBackClick  }) => {
  return  ( 
    <div className="modal-wrapper">
      <div className="modal animation">
        {endGameText} 
      </div>
      <div className="modal-content">
        <button className="back-button" onClick={onBackClick}>
          Back to Room List
        </button>
      </div>
    </div>
  );
};

export default EndGameModal;
