import { io } from "socket.io-client";

const socket = io("https://gameserver.pumpsquid.com/", {
  transports: ["websocket"], // Ensure WebSocket transport is used
  autoConnect: true, // Automatically connect on initialization
});
// const socket = io("http://localhost:3001", {
//   transports: ["websocket"], // Ensure WebSocket transport is used
//   autoConnect: true, // Automatically connect on initialization
// });

export default socket;
