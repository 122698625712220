import React, { useEffect, useState } from "react";
import "./MusicPlayer.css";
const MusicPlayer = ({ track, isMuted, onToggleMute }) => {
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    // Cleanup old audio
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    // Set new audio
    const newAudio = new Audio(track);
    newAudio.loop = true;

    if (!isMuted) {
      newAudio.play().catch((err) => console.error("Audio playback failed:", err));
    }

    setAudio(newAudio);

    return () => {
      if (newAudio) {
        newAudio.pause();
        newAudio.currentTime = 0;
      }
    };
  }, [track]);

  useEffect(() => {
    if (audio) {
      if (isMuted) {
        audio.pause();
      } else {
        audio.play().catch((err) => console.error("Audio playback failed:", err));
      }
    }
  }, [isMuted, audio]);

  return (
    <button className="music-button"
      style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        backgroundColor: isMuted ? "#ff4d4d" : "#4caf50",
        color: "white",
        border: "none",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        cursor: "pointer",
        fontSize: "16px",
      }}
      onClick={onToggleMute}
    >
      {isMuted ? "🔇" : "🔊"}
    </button>
  );
};

export default MusicPlayer;
