import React, { useEffect, useState } from "react";
import "./SlotMachine.css";

const TOKEN = "SOL"
const SlotMachine = ({ candidates, winner, prize, onComplete }) => {
  const [spinningName, setSpinningName] = useState(""); // Single name displayed
  const [showWinner, setShowWinner] = useState(false);

  useEffect(() => {
    if (candidates.length === 0) return;

    // Set up the spinning interval
    let spinInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * candidates.length);
      setSpinningName(candidates[randomIndex]); // Show one name at a time
    }, 100); // Change the displayed name every 100ms

    // Stop spinning after 5 seconds and show the winner
    setTimeout(() => {
      clearInterval(spinInterval);
      setSpinningName(winner); // Set the winner's name
      setShowWinner(true); // Show the winner message
      if (onComplete) onComplete(winner);
    }, 5000); // 5 seconds spinning duration

    return () => clearInterval(spinInterval);
  }, [candidates, winner, onComplete]);

  return (
    <div className="slot-machine-overlay">
      <div className="slot-machine-container">
        <div className="slot-machine-content">
          {showWinner ? (
            <div className="winner-message">
              🎉 Winner: <span className="winner-name">{winner}</span> 🎉
              <br />
              💰 Prize: {prize} {TOKEN} 💰
            </div>
          ) : (
            <div className="spinning-name">
              {spinningName} {/* Display only one name at a time */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlotMachine;
