import React from "react";

import './Header.css';

const Header = ({ turnMessage, roomName,onConnectWallet, walletAddress,disableWallet}) => {
  console.log("disableWallet",disableWallet)
  return (
    <div className="header">
      <div className="room-info">{roomName ? `Room: ${roomName}` : "N/A"}</div>
      <div className="title">SQUID GAME: Glass Bridge Challenge</div>
      <div className="turn-indicator">{turnMessage}</div>
      {/* Connect Wallet Button */}
      <button className="connect-wallet" onClick={onConnectWallet} disabled={disableWallet}> 
        {walletAddress
          ? `Wallet: ${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`
          : "Connect Wallet"}
      </button>
      
    </div>
  );
};

export default Header;
